.add-stock-take-items-dialog {
  .p-dialog-content {
    display: flex;
    overflow-y: hidden;
    #two-dialog-content {
      width: 100%;
    }
  }
  #loaded_inventory_item_list_container, #newly_assigned_inventory_item_list_container {
    height: 45%;
  }
}
