.form-filter {
  height: calc(1em + 0.5rem + 2px);
  padding: 0.25rem 0 0.25rem 2px;
  font-size: 0.875rem;
  line-height: 0.8;
  width: 100%;

  .p-multiselect-label,
  .p-dropdown-label {
    padding: 0rem 0.75rem !important;
  }

  .p-multiselect-clear-icon {
    right: 2rem !important;
  }
}

.date-filter {
  .p-inputtext {
    height: calc(1em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 0.8;
    width: 100%;
  }
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1rem;
}

.p-dropdown-items-wrapper {
  max-height: none !important;
}

.top-bar {
  display: flex;

  .top-paginator {
    margin: auto;
  }

  .button-set {
    margin: auto;
  }
}

#ifmcontentstoprint,
.ifmcontentstoprint {
  height: 0px;
  width: 0px;
  position: absolute;
}

.print-area {
  display: none;
}

//order stage badge style and colors
.stage-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.stage-alu-required,
  &.stage-alu-needs-cutting,
  &.stage-powder-required,
  &.stage-ready {
    background-color: #5486ea;
    color: #fff;
  }
  &.stage-new {
    background-color: #c724b1;
    color: #fff;
  }
  &.stage-alu-cutting,
  &.stage-sent-to-floor,
  &.stage-in-production {
    background-color: #ff9f42;
    color: #000;
  }
  &.stage-cancelled {
    background-color: #ea5455;
    color: #fff;
  }
  &.stage-dispatched,
  &.stage-done {
    background-color: #29c76f;
    color: #fff;
  }
  &.stage-waiting-for-return,
  &.stage-waiting-4-material,
  &.stage-alu-ordered,
  &.stage-powder-ordered,
  &.stage-post-fix-return,
  &.stage-between-factories,
  &.other-factory {
    background-color: #bcbec0;
    color: #000;
  }
  &.stage-on-hold {
    background-color: #000;
    color: #fff;
  }
  &.prio-2 {
    background-color: #ffec33;
  }
  &.prio-3 {
    background-color: #ea5455;
    color: #fff;
  }
}

.stage-badge-filter {
  padding: 0.1px 0.1px;
  text-transform: none !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  letter-spacing: inherit !important;
  height: 100%;
}

.stage-filter {
  .p-multiselect-label {
    height: 100%;
  }
}

//purchase order stage badge style and colors
.stage-badge {
  &.stage-po-draft {
    background-color: #c724b1;
    color: #fff;
  }

  &.stage-po-delayed,
  &.stage-po-partially-delivered {
    background-color: #ff9f42;
    color: #000;
  }

  &.stage-po-delivered {
    background-color: #29c76f;
    color: #fff;
  }
  &.stage-po-eta-confirmed,
  &.stage-po-ordered {
    background-color: #bcbec0;
    color: #000;
  }
  &.stage-po-cancelled {
    background-color: #ea5455;
    color: #fff;
  }
}

.p-datatable-row-expansion {
  > td:first-child {
    padding: 0px 10px 0px 50px !important;
  }
}

.p-datatable .p-datatable-row-expansion {
  //this is for descendants table's tables
  th,
  .p-paginator {
    //header and footer color of table's child table
    background-color: #dbe5ed !important;
  }
  .p-datatable-row-expansion {
    th,
    .p-paginator {
      //header and footer color of table's grandchild table
      background-color: #d9e7df !important;
    }
  }
}
