.shipment-label-detail {
  display: flex;
  height: 90%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  .table-split-button {
    width: 115px;
  }
}

.shipment-label-dialog {
  .p-dialog-content {
    display: flex;
  }

  .p-datatable-tbody,
  .p-datatable-scrollable-body-table,
  .p-datatable-thead,
  .p-datatable-scrollable-header-table {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }

  .p-datatable-scrollable-colgroup {
    display: none;
  }
}
