//oos stage badge style and colors
.oos-stage-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.oos-stage-eta-not-confirmed {
    background-color: #c724b1;
    color: #fff;
  }
  &.oos-stage-delayed {
    background-color: #ff9f42;
    color: #000;
  }
  &.oos-stage-available {
    background-color: #29c76f;
    color: #fff;
  }
  &.oos-stage-eta-confirmed {
    background-color: #bcbec0;
    color: #000;
  }
}

.oos-orders-table {
  width: 100%;
  .p-datatable-scrollable-colgroup {
    display: none;
  }
}
