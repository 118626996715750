#print-area {
  display: none;
}

.cut-sheet {
  @media print {
    cutting-sheet-page {
      //position:relative;
      page-break-after: always;
    }
  }

  @media print {
    @page port {
      size: portrait;
    }
    .portrait {
      max-height: 297mm;
      width: 210mm;
    }

    @page land {
      size: landscape;
    }
    .landscape {
      max-height: 210mm;
      width: 297mm;
    }

    .break {
      page-break-before: always;
    }
  }
  //hide print content for print page
  .cutting-sheet-page {
    @media screen {
      display: none;
    }
    page-break-after: always;
  }

  //show print content for order print view
  body {
    .cutting-sheet-page {
      @media screen {
        display: block;
      }
      page-break-after: always;
    }
    @media print {
      print-color-adjust: exact;
    }
  }
  //This is for view page
  #content {
    .cutting-sheet-page {
      //position:relative;
      @media screen {
        &.portrait {
          width: 210mm;
          min-height: 297mm;
        }
        &.landscape {
          width: 297mm;
          min-height: 210mm;
        }
      }
      page-break-after: always;
    }
  }

  .cutting-sheet-page {
    page-break-after: always;
    background: white;
    .cutting-sheet-body {
      //padding: 6.4mm 11.5mm; //todo vertical margin
      width: 100%;
      height: 100%;
    }
    &.horizontal {
      .cutting-sheet-body {
        //padding: 11.5mm 6.4mm; //todo horizontal margin
      }
    }
    .row,
    .d-flex {
      padding: 0;
      margin: 0;
    }
    .label {
      text-align: right;
      color: lightgray;
      font-size: 3mm;
    }
    .number {
      font-size: 4mm;
    }
    .text {
      font-size: 4mm;
    }
    .label,
    .text {
      padding-left: 0.5mm;
      padding-right: 0.5mm;
      line-height: 5.5mm;
    }
    .item-number,
    .title {
      text-align: center;
      font-weight: bold;
      font-size: large;
    }
    //margins
    .mb-2mm {
      margin-bottom: 2mm;
    }
    .mt-2mm {
      margin-top: 2mm;
    }
    .mt-5 {
      margin-top: 5px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .check-sum {
      border-bottom: 3px double #dee2e6;
    }
    table,
    th,
    td {
      //border: 1px solid lightgray;
      border: 0;
    }

    table {
      font-size: small;
      width: 100%;
      border-spacing: 0;
      padding: 1.5mm 2mm 1.5mm 2mm;
      box-sizing: unset;
      .label {
        text-align: right;
        color: #c1c1c1;
        font-size: 3mm;
      }

      .text-align-center {
        text-align: center;
      }

      td {
        padding: 1.5mm 1mm;
      }

      &.header {
        border-collapse: collapse;
        border: 1px solid black;
        line-height: 75px;
        box-sizing: content-box;
        td.label {
          width: 80px;
        }
      }

      &.summary {
        margin-top: 5mm;
        border: 1px #191919;
        border-top: double;
        td:nth-child(1) {
          width: 80px;
        }
        td:nth-child(2) {
          font-size: x-large;
        }
      }

      &.footer {
        border-collapse: collapse;
        border: 1px solid black;
        line-height: 75px;
        td.label {
          width: 80px;
        }
      }

      &.content {
        tr:nth-child(1) {
          td {
            border-top: 3px solid black;
          }

          td:nth-child(1) {
            text-align: center;
            font-weight: bold;
            font-size: large;
            width: 10mm;
          }
        }

        tr:nth-child(n + 2) {
          td:nth-child(1) {
            color: white;
          }

          td:nth-child(n + 3) {
            border-top: 1px solid black;
            &.internal-split {
              border-top: 10px solid grey;
            }
          }
        }

        td {
          &.sign-off,
          &.sign-off-top,
          &.sign-off-bottom {
            width: 1cm;
            height: 5mm;
          }

          &.sign-off {
            border: solid black 1px;
            text-align: center;
            color: lightgray;
            font-size: 3mm;

            span {
              color: black;
            }
          }

          &.sign-off-top {
            border-top: solid black 1px;
            border-left: solid black 1px;
            border-right: solid black 1px;
          }

          &.sign-off-bottom {
            border-bottom: solid black 1px;
            border-left: solid black 1px;
            border-right: solid black 1px;
          }

          &.no-top-border {
            border-top: 1px solid white;
          }

          &.internal-split {
            border-top: 10px solid grey;
          }

          &.index {
            width: 10mm;
          }
        }
      }
    }

    .two-cols {
      td {
        &.label {
          min-width: 17mm;
        }
        &.number {
          width: 20mm;
        }
        &.text {
          width: 100mm;
        }
      }
    }

    .three-cols {
      td {
        &.label {
          width: 17mm;
        }
        &.number {
          width: 20mm;
        }
        &.text {
          width: 50mm;
        }
      }
    }

    .four-cols {
      td {
        &.label {
          width: 17mm;
        }
        &.number {
          width: 15mm;
        }
        &.text {
          width: 45mm;
        }
      }
    }

    .cut-sheet-restofpage {
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .cut-sheet-footer {
    }
  }

  .avoid-break {
    break-inside: avoid;
  }

  .justify-content-start {
    justify-content: start;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-end {
    justify-content: end;
  }

  .justify-content-between {
    justify-content: space-between;
  }
}

.cut-sheet {
  @media print {
    @page port {
      size: portrait;
    }
    .portrait {
      max-height: 297mm;
      width: 210mm;
    }

    @page land {
      size: landscape;
    }
    .landscape {
      max-height: 210mm;
      width: 297mm;
    }
  }
  .cutting-sheet-page {
    page-break-after: always;
    background: white;
    @media screen {
      //display: none;
      &.portrait {
        width: 210mm;
        min-height: 297mm;
      }
      &.landscape {
        width: 297mm;
        min-height: 210mm;
      }
    }
    @media print {
      print-color-adjust: exact;
    }
    .cutting-sheet-body {
      width: 100%;
      height: 100%;
    }

    .row,
    .d-flex {
      padding: 0;
      margin: 0;
    }
    .label {
      text-align: right;
      color: lightgray;
      font-size: 3mm;
    }
    .number {
      font-size: 4mm;
    }
    .text {
      font-size: 4mm;
    }
    .label,
    .text {
      padding-left: 0.5mm;
      padding-right: 0.5mm;
      line-height: 5.5mm;
    }
    .item-number,
    .title {
      text-align: center;
      font-weight: bold;
      font-size: large;
    }
    //margins
    .mb-2mm {
      margin-bottom: 2mm;
    }
    .mt-2mm {
      margin-top: 2mm;
    }
    .mt-20mm {
      margin-top: 20mm;
    }
    .mt-5 {
      margin-top: 5px;
    }
    .mt-10 {
      margin-top: 10px;
    }
    .check-sum {
      border-bottom: 3px double #dee2e6;
    }
    table,
    th,
    td {
      //border: 1px solid lightgray;
      border: 0;
    }

    table {
      font-size: small;
      width: 100%;
      border-spacing: 0;
      padding: 1.5mm 2mm 1.5mm 2mm;
      box-sizing: unset;
      .label {
        text-align: right;
        color: #c1c1c1;
        font-size: 3mm;
      }

      .text-align-center {
        text-align: center;
      }

      td {
        padding: 1.5mm 1mm;
      }

      &.header {
        border-collapse: collapse;
        border: 1px solid black;
        line-height: 75px;
        box-sizing: content-box;
        td.label {
          width: 80px;
        }
      }

      &.summary {
        margin-top: 5mm;
        border: 1px #191919;
        border-top: double;
        td:nth-child(1) {
          width: 80px;
        }
        td:nth-child(2) {
          font-size: x-large;
        }
      }

      &.footer {
        border-collapse: collapse;
        border: 1px solid black;
        line-height: 75px;
        td.label {
          width: 80px;
        }
      }

      &.content {
        tr:nth-child(1) {
          td {
            border-top: 3px solid black;
          }

          td:nth-child(1) {
            text-align: center;
            font-weight: bold;
            font-size: large;
            width: 10mm;
          }
        }

        tr:nth-child(n + 2) {
          td:nth-child(1) {
            color: rgba(255, 255, 255, 0);
          }

          td:nth-child(n + 3) {
            border-top: 1px solid black;
          }
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }

        td {
          &.sign-off,
          &.sign-off-top,
          &.sign-off-bottom {
            width: 1cm;
            height: 5mm;
          }

          &.sign-off {
            border: solid black 1px;
            text-align: center;
            color: lightgray;
            font-size: 3mm;

            span {
              color: black;
            }
          }

          &.sign-off-top {
            border-top: solid black 1px;
            border-left: solid black 1px;
            border-right: solid black 1px;
          }

          &.sign-off-bottom {
            border-bottom: solid black 1px;
            border-left: solid black 1px;
            border-right: solid black 1px;
          }

          &.no-top-border {
            border-top: 1px solid white;
          }

          &.index {
            width: 10mm;
          }
        }
      }
    }

    .two-cols {
      td {
        &.label {
          min-width: 17mm;
        }
        &.number {
          width: 20mm;
        }
        &.text {
          width: 100mm;
        }
      }
    }

    .three-cols {
      td {
        &.label {
          width: 17mm;
        }
        &.number {
          width: 20mm;
        }
        &.text {
          width: 50mm;
        }
      }
    }

    .four-cols {
      td {
        &.label {
          width: 17mm;
        }
        &.number {
          width: 15mm;
        }
        &.text {
          width: 45mm;
        }
      }
    }

    .justify-content-start {
      justify-content: start;
    }

    .justify-content-center {
      justify-content: center;
    }
    .justify-content-end {
      justify-content: end;
    }

    .justify-content-between {
      justify-content: space-between;
    }

    .cut-sheet-body {
      display: block;
      vertical-align: top;
      height: 875px;
    }
  }
}
