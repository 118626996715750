.order-detail {
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  .p-grid input {
    width: 100%;
  }

  .p-timeline-event-opposite {
    flex: 0;
  }

  .p-dropdown {
    width: 14rem;
  }
}

.order-detail-item {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.order-detail-header {
  width: 100%;
  flex-direction: row;
}

.order-detail {
  .p-card-body {
    overflow: auto;
    height: 100%;
    padding: 0;
    .p-card-content {
      height: 100%;
      padding: 0;
      overflow: auto;
    }
  }
}

.email-to {
  color: var(--blue-300);
}
