.purchase-order-detail {
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  width: 100%;

  .p-grid input {
    width: 100%;
  }

  .p-grid {
    .p-dropdown {
      width: 100%;
    }
  }
  .p-multiselect {
    width: 100%;
  }

  .p-calendar {
    width: 100%;
  }

  .p-fieldset {
    padding: 10px;
    margin: 0px;
  }

  label {
    display: flex;
    flex: 0 0 auto;
  }
}

.po-detail-virtual-scroller {
  height: 400px !important;
}

.purchaser-order-date-dialog {
  .p-dialog-content {
    padding: 0px 0px 0px 10px;
  }
}

.purchase-order-fieldset {
  .p-fieldset-content {
    padding: 0px;
  }
}
