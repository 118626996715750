@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#lead_times_component {
  .setting_part {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #ffffff85;
    z-index: 2;
  }
}
