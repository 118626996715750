.date-panel-filter {
  .p-calendar-w-btn .p-datepicker-trigger {
    padding: 0 !important;
    width: 2rem !important;
  }

  .p-overlaypanel-content {
    padding: 0 !important;
  }

  .p-listbox-list {
    padding: 0 !important;
  }

  .p-listbox {
    &.no-border {
      border: none !important;
    }
  }

  .p-fieldset {
    padding: 5px;
    margin: 8px;
  }

  .p-fieldset .p-fieldset-content {
    padding: 0;
  }

  .p-field > label {
    margin-bottom: 0;
  }
  .p-col-2,
  .p-col-3 {
    padding: 0px;
  }
}

.clear-icon {
  position: absolute;
  margin-top: 4px;
  cursor: pointer;
  margin-left: -15px;
}
