.inventory-item-detail {
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  .p-grid input {
    width: 100%;
  }

  .p-grid {
    .p-dropdown {
      width: 100%;
    }
  }
  .p-multiselect {
    width: 100%;
  }
}

.p-dropdown-panel {
  z-index: 9999 !important;
}

.inventory-item-dialog {
  .p-dialog-content {
    overflow: auto !important;
  }
}

.supplier-grid {
  label {
    width: 12.3333%;
  }

  div {
    width: 87.6667%;
  }
}

.po-link{
cursor: pointer;
}
