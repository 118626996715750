@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.standard,
.repair,
.component {
  .table-container {
    border: solid $primaryColor;
    border-width: 1px !important;
  }
}
