.orders-scheduler {
  .orders-scheduler-table {
    .p-rowgroup-header {
      background-color: #dbe5ed !important;
      .p-rowgroup-header-name {
        width: 100%;
      }
    }
  }
}
