#alarms-table {
  .detail-collapse {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
  }
}


