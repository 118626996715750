@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.page-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  flex: 1 1 100%;
  width: 100%;
}

#site-logo-large {
  display: inline;
  width: 50px;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.vertical-scroll {
  overflow-y: scroll;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.h-2em {
  height: 100px;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

.raise-on-hover:hover {
  transform: translateY(-0.25em);
}

label {
  color: #bcbec0;
}

.w-100 {
  width: 100%;
}

.p-timeline-event {
  min-height: unset !important;
}

.orange {
  background: $warningColor;
}

.red {
  background: $dangerColor;
}
