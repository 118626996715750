//oos stage badge style and colors
.stock-take-item-stage-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.stock-take-item-stage-new {
    background-color: #a600a6;
    color: #ffffff;
  }
  &.stock-take-item-stage-counted {
    background-color: #5486ea;
    color: #ffffff;
  }
  &.stock-take-item-stage-approved {
    background-color: #29c76f;
    color: #ffffff;
  }

  &.stock-take-item-stage-re-count {
    background-color: #ffa500ff;
    color: #000000;
  }
}
