@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.line-filter {
  background: $shade100;
  border-color: $primaryColor;
  border-width: 1px;
  border-radius: 0;

  .dropdown-line {
    width: 210px;
  }

  .p-fieldset-content {
    padding: 0;
    background: $shade100;
  }

  .filter-box-span {
    text-align: right;
    justify-content: flex-end;
    font-weight: 700;
    color: $shade600;
  }

  .p-inputswitch .p-inputswitch-slider {
    background: $shade000;
  }

  .p-inputswitch .p-inputswitch-slider:hover {
    background: scale-color($primaryColor, $lightness: 90%);
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background: $primaryColor;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $shade000;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: $successColor;
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: scale-color($successColor, $lightness: 95%);
  }
}
